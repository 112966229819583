$(document).on('click', '[data-submenu]', function(ev) {
  ev.stopPropagation();

  let $link = $(this);
      target = $link.data('submenu'),
      $menu = $link.closest('.dropdown-menu'),
      $header = $menu.find('.user-dropdown__subsection-header'),
      $submenu = $menu.find('#' + target),
      title = $submenu.data('title');

  $header.find('.user-dropdown__subsection-header-title').text(title);

  $menu.addClass('submenu-open');
  $menu.find('.submenu').removeClass('open');
  $submenu.addClass('open');
});

$(document).on('click', '#back-button', function(ev) {
  ev.stopPropagation();

  let $link = $(this),
      $menu = $link.closest('.dropdown-menu');

  $menu.removeClass('submenu-open');
  $menu.find('.submenu').removeClass('open');
});

$(document).on('click', '.user-dropdown [class^="user-dropdown"]', function(ev) {
  ev.stopPropagation();
});
