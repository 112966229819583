import consumer from "./consumer"
const snackbar = require("../src/scripts/common/snackbar");

$(document).on('page:change', function() {
  if ($('body').hasClass('copy_form')) {

    const subObj = consumer.subscriptions;
    const subArray = subObj.subscriptions.filter(function(sub){
      let identifier = sub.identifier;
      if (!identifier.length) return false;

      return JSON.parse(sub.identifier)['channel'] == 'CloningChannel';
    });
    if (subArray.length) return;

    consumer.subscriptions.create("CloningChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (!data.item_klass) return;

        let selectorPrefix = `[data-item-klass="${data.item_klass}"]`;
        if (data.no_customer) {
          let inputField = $(`${selectorPrefix} [id$="_customer_id"]`).closest('.mdc-text-field')[0]
          if (inputField && inputField.mdcTextField) {
            inputField.mdcTextField.blur();
          } else {
            return;
          }

          let flashSnackbar = document.querySelector('.cb-snackbar');
          if (flashSnackbar) snackbar.show(data.no_customer);
          return;
        }

        if (data.item_id && data.item_html) {
          let selector = '#copy_item_' + data.item_id;

          $(`${selectorPrefix} ${selector}`).replaceWith(data.item_html)
          app.initialize($(selector))
        }
        if (data.new_item_html) {
          let $html = $(data.new_item_html);
          $(`${selectorPrefix} #${data.html_block_id}`).append($html);
          $html.closest('.coppied-item-block').removeClass('coppied-item-block--hidden');
          app.initialize($html);
        }
        if (data.status == 'in_progress') {
          $(`${selectorPrefix} .copy-input-wrapper`).hide();
          $(`${selectorPrefix} #save_button`).hide();
        }
        if (data.status == 'done') {
          $(`${selectorPrefix} #copy_complete`).show();
        }
      }
    })
  }
})
