$(document).on("click", "#ppi_merge_form_link", function(e) {
  var params = [];
  $("input.cb-collection-select:checked").each(function() {
    params.push("ids[]=" + $(this).data("objectId"));
  })
  if (params.length > 0) {
    $(this).attr("href", $(this).attr("href")+"?"+params.join("&"));
  }
});

// Prevention plan item image upload
$(document).on('change', 'form[data-remote="true"].cb-new-file-attachment-form input.file', function(event){
  var files = event.target.files,
      image = files[0],
      reader = new FileReader(),
      $el = $(this),
      target_id = $el.data('target'),
      form = $el.closest('form')[0],
      $newAttBlock = $("<div class='mdc-card mdc-card--outlined cb-file-attachment-wrapper'></div>");

  // Render image placeholder;
  reader.onload = function(file) {
    var $image = $('<div class="mdc-card__media"></div>'),
        $panel = $el.closest('.cb-expansion-panel');

    $newAttBlock.append($image);
    $newAttBlock.append($('<i class="cb-loader"></i>'));
    if ($panel.length) $panel[0].adjustSiblings();

    $image.css({
      backgroundImage: 'url('+file.target.result+')',
      opacity: '0.35',
      filter: 'grayscale(0.8)'
    });
  }

  if (image && image.type.match('image.*')) {
    var $parent = $el.closest('.show-for-content');
    $parent.find('.cb-file-attachments__list').append($newAttBlock);


    // Read file (image)
    reader.readAsDataURL(image);

    // Block newFileAttachment button;
    $parent.find('.new_file_attachment').attr('disabled', true);

    // Submit form
    Rails.fire(form, 'submit');
  } else {
    form.reset();

    let flashSnackbar = document.querySelector('.cb-snackbar');
    if (flashSnackbar) snackbar.show(I18n.t('flash.attachment.invalid_file_type'));
  };
});

$(document).on('change', 'select#prevention_plan_item_responsible_person_type', function () {
  let selectVal = $(this).val();

  if (selectVal == 'choose_user') {
    showInputs('prevention_plan_item_owners');
    hideInputs('prevention_plan_item_responsible_customer_employee_id',
               'prevention_plan_item_responsible_person');
  } else if (selectVal == 'choose_customer_employee') {
    showInputs('prevention_plan_item_responsible_customer_employee_id');
    hideInputs('prevention_plan_item_owners',
               'prevention_plan_item_responsible_person');
  } else {
    showInputs('prevention_plan_item_responsible_person');
    hideInputs('prevention_plan_item_owners',
               'prevention_plan_item_responsible_customer_employee_id');
  }
});

// NOTE: 'If select triggers select (with tags)'
// $(document).on('change', 'select#prevention_plan_item_responsible_customer_employee', function () {
//   let empId = $(this).val(),
//       target = $("#prevention_plan_item_responsible_person");
//
//   target.find("option[data-tmp]").remove();
//
//   if (empId) {
//     $.get('/customer_employees/'+ empId+'.json', function(data) {
//       let newVal = data.name + ' ' + data.surname;
//
//       if (target.find("option[value='" + newVal + "']").length) {
//         target.val(newVal).trigger('change');
//       } else {
//         // Create a DOM Option and pre-select by default
//         let newOption = new Option(newVal, newVal, true, true);
//         $(newOption).attr('data-tmp', '');
//
//         // Append it to the select
//         target.prepend(newOption).trigger('change');
//       }
//
//     });
//   } else {
//     target.val(null).trigger('change');
//   }
// });

$(document).on('change', 'select#prevention_plan_item_deadline', function () {
  var selectVal = $(this).val(),
      showInput = selectVal == 'date';

  if (showInput) {
    showInputs('prevention_plan_item_deadline_date');
  } else {
    hideInputs('prevention_plan_item_deadline_date')
  }
});

$(document).on('change', 'select#prevention_plan_template_item_deadline_type', function () {
  var selectVal = $(this).val(),
      showInput = selectVal == 'date';

  if (showInput) {
    showInputs('prevention_plan_template_item_deadline');
  } else {
    hideInputs('prevention_plan_template_item_deadline')
  }
});


$(document).on('change', '#prevention_plan_item_form_type', function() {
  let value = $(this).val(),
      selector = 'form#new_prevention_plan_item';

  $(selector + ' fieldset').prop('disabled', true).hide();
  $(selector + ' .fieldset_for_' + value).prop('disabled', false).show();

  let add_new = value == 'add_new';
  $(selector + ' .cb-nested-forms').prop('disabled', !add_new).toggle(add_new);
});

$(document).on('change', '#ppi_prevention_plan_template_id', function() {
  let input = $(this).closest('form').find('#prevention_plan_item_prevention_plan_template_item_ids');
  input.val('').change();
});
