// Cloneable form
$(document).on('change', '[id$="_cloneable_for"]', function() {
  let isToAllChildren = $(this).val() == 'all_children',
      $form = $(this).closest('form'),
      $input = $form.find('#copyToCustomerId'),
      textField = $input.closest('.mdc-text-field')[0];

  if (textField && textField.mdcTextField) {
    textField.mdcTextField.foundation.setDisabled(isToAllChildren);
  } else {
    $input.prop('disabled', isToAllChildren);
  }

  $form.find('[class*="_customer_id"]').toggle(!isToAllChildren);
  $form.find('.copy-current-customer').toggle(isToAllChildren);
});
