import { requireAll } from "./utils";

function initWebComponents() {
  const components = requireAll(require.context('./meemo_ui/', true, /\.js$/, 'lazy'));

  components.forEach(filePath => {
    import(`./meemo_ui/${filePath.slice(2)}`).then((module) => {
      const { initWebComponent } = module;

      if (typeof initWebComponent == 'function') {
        initWebComponent();
      }
    });
  });
}

export { initWebComponents }
