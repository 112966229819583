$(document).on('click', '.cb-translations-tab-bar button', function() {
  let locale = $(this).attr('data-locale'),
      $wrapper = $(this).closest('.mdc-layout-grid__inner'),
      $fieldset = $wrapper.find('fieldset[data-locale='+ locale +']');

  if ($fieldset) {
    $wrapper.find('fieldset[data-locale]').addClass('cb--hidden');
    $fieldset.removeClass('cb--hidden');
  }
});
