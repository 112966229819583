$(document).on('change', '#recommended_preventive_action', function() {
  const $textarea = $('#recommended_preventive_actions');
  const $option = $(this).find('option:selected');
  const oldVal = $textarea.val();
  let newVal = oldVal;

  if ($option.length) {
    if (newVal.length) newVal += '\r\n'
    newVal += $option.text()
    $textarea.val(newVal).trigger('change');
  }
})

$(document).on('change', '#recommended_preventive_subcategory', function () {
  $('#recommended_preventive_action').val('').trigger('change');
})