$(document).on('click', '.password input[name*=password] + .mdc-text-field__icon', function() {
  let $icon = $(this),
      $input = $icon.prev('input'),
      show = $input.attr('type') === 'password',
      newType = show ? 'text' : 'password',
      icon = show ? 'visibility' : 'visibility_off';

  $input.attr('type', newType);
  $icon.text(icon);
});

let inputDelayTimeout;
$(document).on('keydown change', '.password input[name*=password]', function() {
  clearTimeout(inputDelayTimeout);
  inputDelayTimeout = setTimeout(validatePassword, 1000);
});

function validatePassword() {
  const password = $('.password input[name*=password]').val();

  // Rules object
  const rules = {
      'length9': password.length >= 8,
      'smallChr': /[a-z]/.test(password),
      'bigChr': /[A-Z]/.test(password),
      'numberOrSpecial': /[0-9!@#$%^&*(),.?":{}|<>]/.test(password)
  };

  // Loop through rules and update UI
  for (const [ruleId, isValid] of Object.entries(rules)) {
    let $rule = $('#' + ruleId + ' .status');
    $rule.text(isValid ? 'check' : 'clear');
    $rule.toggleClass('status--valid', isValid);
  }
}
