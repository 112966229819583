$(document).on('change', '.risk-assessment-items .risk_assessment_customer_occupations', function() {
  toggleUnevaluatedOccupationsSidebarItems();
})

$(document).on('cb:fields-removed', '.risk-assessment-items', function () {
  console.log('removed element', $(this))
  toggleUnevaluatedOccupationsSidebarItems();
})

$(document).on('change', '#risk_assessment_content_type', function () {
  let value = $(this).val(),
      asFile = value == 'file';
  if (asFile) {
      toggleAssessmentFileInput(true)
  } else {
      toggleAssessmentFileInput(false)
  }
})

// Risk assessment (new) form
$(document).on('click', '.cb-box-select[data-target="#template_type"] .cb-box-select__item:not(.cb-box-select__item--active)', function() {

  let $item = $(this),
      title = $item.attr('data-input-label'),
      isBlank = $item.attr('data-value') == '',
      $template = $('#risk_assessment_template');

  $template.val('').trigger("change");
  $template.closest('.mdc-text-field').find('#template-label').html(title);
  $template.closest('.form-input-wrapper').toggle(!isBlank);

  // Hide file input When using any template
  if (isBlank) $template.closest('form').find('#risk_assessment_content_type').trigger("change");
  $template.closest('form').find('.risk_assessment_content_type-wrapper').toggle(isBlank);
});

function toggleUnevaluatedOccupationsSidebarItems() {
  let selectedIds = $('.risk-assessment-items .risk_assessment_customer_occupations').map(function () { return $(this).val() }).toArray(),
      $sidebar = $('.unchecked-occupations-sidebar'),
      $sidebarCounter = $sidebar.find('.count');

  $sidebar.find('.unchecked-occupation[data-id]').show().filter(function () {
      let id = $(this).data('id');
      if (id != undefined) { id = id.toString() };
      return selectedIds.includes(id);
  }).hide();
  $sidebarCounter.text($sidebar.find('.unchecked-occupation:visible').length);
};

function toggleAssessmentFileInput(shouldShow) {
  $('#risk_assessment_file').prop('disabled', !shouldShow);
  $('.risk_assessment_file').toggle(shouldShow);
  $('.risk_assessment_file_download').toggle(shouldShow);
}
