$(document).on('change', '#regular_test_instruction_content_type', function() {
  let value = $(this).val();
  if (value == 'description') {
    toggleInstructionInput('description', true)
    toggleInstructionInput('file', false)
  } else {
    toggleInstructionInput('description', false)
    toggleInstructionInput('file', true)
  }
})

$(document).on('change', '#regular_test_instruction_regular_test_instruction_template_id', function() {
  var id = $(this).val();
  if (id){
    $.ajax({
      url: '/regular_test_instruction_templates/'+id+'.json',
      success: function (data) {
        $('#regular_test_instruction_name').val(data.name);
        $('#regular_test_instruction_regular_test_instruction_type_id').val(
          data.regular_test_instruction_type_id
        ).change();

        // Hide file input When using any template
        toggleInstructionInput('file', false)
        if (data.file_file_name) {
          $('#regular_test_instruction_content_type').val('file').change();
          toggleInstructionInput('description', false)
        } else {
          $('#regular_test_instruction_content_type').val('description').change();
          toggleInstructionInput('description', true)
          CKEDITOR.instances['regular_test_instruction_description'].setData(data.description);
        }
      }
    })
  }
})

function toggleInstructionInput(field, toggle, prefix) {
  if (prefix == undefined) { prefix = 'regular_test_instruction' }
  $('#'+prefix+'_'+field).prop('disabled', !toggle);
  $('.'+prefix+'_'+field).toggle(toggle);
}
