$(document).on('change', '#vaccination_template', function() {
  let id = $(this).val();

  if(id){
    $.ajax({
      url: '/vaccination_templates/'+id+'.json',
      success:function (data, status) {
        setMdcTextFieldWithVal( $('#vaccination_name'), data.name );
        setMdcTextFieldWithVal( $('#vaccination_vaccination_category_id'), data.vaccination_category_id );
        setMdcTextFieldWithVal( $('#vaccination_description'), data.description );
        setMdcTextFieldWithVal( $('#vaccination_period'), data.period );
      }
    });
  }
});

function setMdcTextFieldWithVal($field, val){
  $field.val(val).trigger('change');

  let textField = $field.closest('.mdc-text-field')[0];
  if (textField && textField.mdcTextField) textField.mdcTextField.blur();
}

$(document).on('change', 'select[id="vaccination_form_select"]', function () {
  // Show/hide employee/occupations select for vaccinations
  var occupationsSelect = $('select[id="vaccination_customer_occupation_ids"]'),
      employeeSelect = $('select[id="vaccination_customer_employee_ids"]');
  if ($(this).val() === 'specific') {
    occupationsSelect.prop('disabled', false).closest('.select').show();
    occupationsSelect.closest('.mdc-text-field').removeClass('mdc-text-field--disabled');
    employeeSelect.prop('disabled', false).closest('.select').show();
    employeeSelect.closest('.mdc-text-field').removeClass('mdc-text-field--disabled');
  } else {
    occupationsSelect.prop('disabled', true).closest('.select').hide();
    occupationsSelect.closest('.mdc-text-field').addClass('mdc-text-field--disabled');
    employeeSelect.prop('disabled', true).closest('.select').hide();
    employeeSelect.closest('.mdc-text-field').addClass('mdc-text-field--disabled');
  }
})
