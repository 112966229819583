function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./views/', true, /\.js$/));

// require('./plugins/tawk_to');
require('./plugins/shepherd');

require('./common/link');
requireAll(require.context('./plugins/esigning/', true, /\.js$/));

// List forms
$(document).on("ajax:success", ".list-form", function() {
  loadTabSection($(this).closest(".cb-tab-section"));
})

// Remove element wrapper
$(document).on('click', '.remove-fields', function () {
  let target = $(this).data('target'),
      $target = target ? $(this).closest(target) : $(this).parent(),
      $context = $target.parent();
  $target.slideUp(function () {
    $(this).remove();
    $context.trigger('cb:fields-removed');
  })
})

/*
  Bottom sheets
*/

// Close opened sheets on blur
// $(document).on('click', function (e) {
//   if (!$(e.target).closest('.cb-bottom-sheet').length) {
//     $('.cb-bottom-sheet.cb-bottom-sheet--expanded').removeClass('cb-bottom-sheet--expanded').addClass('cb-bottom-sheet--collapsed')
//   }
// })

$(document).on('click', '.cb-bottom-sheet__label', function () {
  const $sheet = $(this).closest('.cb-bottom-sheet');
  if ($sheet.hasClass('cb-bottom-sheet--collapsed')) {
    $sheet.trigger('cb:open')
  } else {
    $sheet.trigger('cb:close')
  }
})

$(document).on('click', '.cb-bottom-sheet__close', function () {
  $(this).closest('.cb-bottom-sheet').html('');
})

$(document).on('cb:open', '.cb-bottom-sheet', function () {

  $(this).addClass('cb-bottom-sheet--transitioning')
  $(this).removeClass('cb-bottom-sheet--collapsed').addClass('cb-bottom-sheet--expanded')

  $(this).one('transitionend', function() {
    $(this).removeClass('cb-bottom-sheet--transitioning')
  })
})

$(document).on('cb:close', '.cb-bottom-sheet', function() {
  $(this).addClass('cb-bottom-sheet--transitioning')
  $(this).removeClass('cb-bottom-sheet--expanded').addClass('cb-bottom-sheet--collapsed')
  $(this).one('transitionend', function () {
    $(this).removeClass('cb-bottom-sheet--transitioning')
  })
})

// Allow for submit buttons outside forms
$(document).on('click', 'button[type=submit][for]', function () {
  $('#' + $(this).attr('for')).trigger('submit');
})