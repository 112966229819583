$(document).on("click", ".mdc-chip-set .mdc-chip", function(e) {
  deactivateActiveGroup($(this));
});

// Traffic light scope groups
$(document).on("click", ".cb-lights-wrapper .cb-light", function(e) {
  e.stopPropagation();
  deactivateActiveGroup($(this));
  $(this).closest('.cb-chip').toggleClass('cb-chip-group--active', true);
});

$(document).on("click", ".cb-chip-group", function(e) {
  e.preventDefault();
  e.stopPropagation();
  let firstLight = this.querySelector('.cb-light');

  if (!firstLight.classList.contains('scope-current')) {
    firstLight.click();
  }
});

function deactivateActiveGroup($this) {
  if ($this) {
    let $activeGroup = $this.closest('.mdc-chip-set').find('.cb-chip-group--active')
    if ($activeGroup.length) $activeGroup.removeClass('cb-chip-group--active');
  }
};
