$(document).on("click", ".js-sign-document-pdf-preview-link", function(e) {
  let pdf_url = $(this).attr("data-pdf-url"),
      document_url = $(this).attr("data-document-url"),
      documentData = $(this).closest('.read-sidebar__item').data("document"),
      $link = $(this);

  if (!pdf_url) { return false; }

  $(".js-sign-document-pdf-preview-link").closest('.read-sidebar__item').removeClass('read-sidebar__item--current');
  $link.closest('.read-sidebar__item').addClass('read-sidebar__item--current');

  setQuickNavAndPdf(0);

  // Load pdf preview
  // $("#sign_document_pdf_preview").html(html);
  $('#sign_document_pdf_preview embed').attr('src', pdf_url);

  // Reset comment field
  $("#reject_document_comment").val("");

  // Update rejection form
  $("#reject_document_form").attr("data-url", document_url);
  $("#reject_document_form").attr("data-document", documentData);
})


// Signer block navigation
$(document).on('click', '.js-signature-block-next-document', function() {
  let toIndex = setQuickNavAndPdf(1);
  setActivePdfPreview(toIndex);
});

$(document).on('click', '.js-signature-block-prev-document', function() {
  let toIndex = setQuickNavAndPdf(-1);
  setActivePdfPreview(toIndex);
});

function setQuickNavAndPdf(diff){
  let $allLinks = $(".js-sign-document-pdf-preview-link"),
      len = $allLinks.length;

  // TODO: Redirect to parent if (len == 0) { ... }

  let currentLink = $(".read-sidebar__item--current .js-sign-document-pdf-preview-link")[0],
      currentIndex = $allLinks.index(currentLink),
      toIndex = currentIndex + diff,
      maxIndex = len - 1;

  // Loop: Last to first and first to last
  if (toIndex > maxIndex) {
    toIndex = 0;
  } else if (toIndex < 0) {
    toIndex = maxIndex;
  }

  // Set quick nav counter
  $('.quick-nav .quick-nav__num span').text((toIndex + 1) + ' / ' + len);

  return toIndex;
}



$(document).on("click", "#reject_document", function() {
  let $form = $("#reject_document_form"),
      url = $form.attr("data-url"),
      $submitButton = $(this);
  if (!url) { return false; }

  // Disable the reject button
  $submitButton.prop('disabled', true);

  let comment = $("#reject_document_comment").val(),
      documentData = $form.attr('data-document'),
      $sidebarDocument = $('.read-sidebar__item[data-document="'+documentData+'"]'),
      documentType = documentData.split('-')[0].toLowerCase(),
      data = {};

  data[documentType] = {
    state: "recipient_rejected",
    comments_attributes: [{ comment: comment, user_id: currentUser }]
  };
  data['notify_for_reject'] = true
  $.ajax({
    url: url,
    data: data,
    dataType: 'JSON',
    method: "PUT",
    success: function() {
      // Enable the reject button
      $submitButton.prop('disabled', false);
      // Remove document from sidebar
      $sidebarDocument.remove();

      // Replace active pdf preview
      let toIndex = setQuickNavAndPdf(0);
      setActivePdfPreview(toIndex);
    }
  })
})

// Toggle CustomerInstruction hidden input values when CustomerInstructionList gets toggled
$(document).on("change", '#new_signature_block .selectable-document[data-document-type="CustomerInstructionList"]', function(e) {
  var checked = $(this).prop('checked');
  var $customerInstructions = $('#new_signature_block .selectable-document[data-document-type="CustomerInstruction"]');
  if (checked) {
    $customerInstructions.val(1);
  } else {
    $customerInstructions.val(0);
  }
})

function setActivePdfPreview(index) {
  if (index === undefined) { index = 0; }
  let $link = $(".js-sign-document-pdf-preview-link").eq(index);
  $link.click();
}
