$(document).on("change", "#equipment_item_template_id", function() {
  const id = $(this).val();
  const updateTextInput = function(selector, newValue) {
    const $element = $(selector);
    if (!$element.length) return false;

    $element.val(newValue).trigger("change");
    $element[0].closest(".mdc-text-field").mdcTextField.blur(true);
  }

  if (id) {
    $.ajax({
      method: "GET",
      url: "/equipment_templates/"+id+".json",
      success: function(data) {
        updateTextInput("#equipment_item_category_id", data.category_id);
        updateTextInput("#equipment_item_name", data.name);
        updateTextInput("#equipment_item_description", data.description);
        updateTextInput("#equipment_item_maintenance_frequency", data.maintenance_frequency);

        $("#equipment_item_is_dangerous").prop("checked", data.is_dangerous).trigger("change");
      }
    })
  }
})
