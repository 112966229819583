$(document).on("click", ".side-sheet-button", function(e) {
  let sideSheet = document.querySelector('.side-sheet'),
      toggle = sideSheet.classList.contains('side-sheet--open');

  if (window.innerWidth >= 840) {
    toggle = toggle || sideSheet.classList.contains('side-sheet--with-filter');
  }
  sideSheetToggle(!toggle);
})

$(document).on("click", ".side-sheet-scrim", function(e) {
  sideSheetToggle(false);
})

$(document).on("transitionend webkitTransitionEnd oTransitionEnd", ".side-sheet", function(e) {
  let sideSheet = document.querySelector('.side-sheet'),
      classList = sideSheet.classList;

  if (classList.contains('side-sheet--opening')) {
    // Step2: open
    classList.remove("side-sheet--opening", "side-sheet--animate");

  } else if (classList.contains('side-sheet--closing')) {
    // Step4: closed (default)
    classList.remove("side-sheet--open", "side-sheet--closing", "side-sheet--with-filter")
  }
})

// Opening and closing

// 0 default: side-sheet
// 1 opening: side-sheet side-sheet--open side-sheet--opening side-sheet--animate
// 2 open   : side-sheet side-sheet--open
// 3 closing: side-sheet side-sheet--open side-sheet--closing
// 4 closed : side-sheet

// Step0: default

function sideSheetToggle(toggle){
  let sideSheetButton = document.querySelector('.side-sheet-button'),
      sideSheet = document.querySelector('.side-sheet'),
      sideSheetContent = document.querySelector('.side-sheet-content'),
      sideSheetScrim = document.querySelector('.side-sheet-scrim');

  if (toggle) {
    // Step1: opening
    sideSheetButton.classList.add('side-sheet--open');
    sideSheet.classList.remove("side-sheet--closing");

    sideSheet.classList.add("side-sheet--open", "side-sheet--animate");
    sideSheetScrim.classList.add("side-sheet-scrim--open");

    if (window.innerWidth <= 839) document.body.bodyScrollStateChange(true);

    setTimeout(function(){
      sideSheetContent.classList.add("side-sheet-content--shrink");
      sideSheet.classList.add("side-sheet--opening");
    }, 10);

  } else {
    // Step3: closing
    sideSheetButton.classList.remove('side-sheet--open');
    sideSheet.classList.remove("side-sheet--animate", "side-sheet--opening");

    document.body.bodyScrollStateChange(false);

    sideSheet.classList.add("side-sheet--closing");
    sideSheetContent.classList.remove("side-sheet-content--shrink",
      "side-sheet-content--with-filter");
    sideSheetScrim.classList.remove("side-sheet-scrim--open");
  }
}
