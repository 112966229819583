$(document).on("page:change", function() {
  const $wrapper = $('#application_stats');
  const url = $wrapper.data('content-url');
  if (!url) return;

  $.ajax({
    url: url,
    success: function(data) {
      $wrapper.html(data);
      init_preloads($wrapper);
    }
  });
})

$(document).on("change", "#submitter_is_self_switch", function () {
  if ($(this).prop('checked')) {
    $("fieldset#change_customer_employee_fields").hide().attr("disabled", true)
    $("#application_user_id").prop("disabled", false)
    $('#submitter_is_not_employee_switch').closest('.form-input-wrapper').hide()
  } else {
    $("fieldset#change_customer_employee_fields").show().attr("disabled", false)
    $("#application_user_id").prop("disabled", true)
    $('#submitter_is_not_employee_switch').closest('.form-input-wrapper').show()
  }

  // Always reset submitter_is_not_employee to off/false
  $('#submitter_is_not_employee_fields').hide().attr("disabled", true);
  $('#submitter_is_not_employee_switch').prop('checked', false);
  let $switch = $('#submitter_is_not_employee_switch').closest('.mdc-switch');
  if ($switch.length) {
    $switch[0].mdcSwitch.foundation.setChecked(false);
  }
})

$(document).on("change", "#submitter_is_not_employee_switch", function () {
  if ($(this).prop('checked')) {
    $("fieldset#submitter_is_not_employee_fields").show().attr("disabled", false)
    $("fieldset#change_customer_employee_fields").hide().attr("disabled", true)
  } else {
    $("fieldset#submitter_is_not_employee_fields").hide().attr("disabled", true)
    $("fieldset#change_customer_employee_fields").show().attr("disabled", false)
  }
})

$(document).on("change", "#application_application_type_id", function() {
  const $option = $(this).find("option:selected");
  let value;

  if ($option.length) value = $option.data("state");
  $("#application_state").val(value).trigger("change");
})

$(document).on("change", "#locationQrCodeId", function () {
  const $option = $(this).find("option:selected");
  let type_id, user_id;

  if ($option.length) {
    type_id = $option.data("typeId");
    user_id = $option.data("userId");
  }
  $("#application_application_type_id").val(type_id).trigger("change");
  $("#application_responsible_user_id").val(user_id).trigger("change");
})
