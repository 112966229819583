// As nested form
$(document).on('change', '[id^=site_visit_participants_attributes][id$=user_id]', function() {
  let $userIdSelect = $(this)
      userId = $userIdSelect.val();
  if (!userId) return;

  $.get('/users/' + userId + '.json', function(data) {
    let $nameInput = $userIdSelect.closest('.cb-nested-form').find('[id^=site_visit_participants_attributes][id$=name]');
    $nameInput.val(data.to_s).trigger('change');
  });
});

// As modal form
$(document).on('change', '#site_visit_participant_user_id', function() {
  let $userIdSelect = $(this)
      userId = $userIdSelect.val();
  if (!userId) return;

  $.get('/users/' + userId + '.json', function(data) {
    let $nameInput = $userIdSelect.closest('form').find('#site_visit_participant_name');
    $nameInput.val(data.to_s).trigger('change');
  });
});
