import consumer from "./consumer"

$(document).on('page:change', function () {
  if ($('body').hasClass('authenticated') && !window.ActionCableSubscriptions.DownloadChannel ) {
    console.info("Subscribing to DownloadChannel ...");
    window.ActionCableSubscriptions['DownloadChannel'] = consumer.subscriptions.create("DownloadChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to DownloadChannel");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.action == "deleted") {
          let $item = $('#myDownloads .cb-bottom-sheet__content [data-id="'+data.id+'"]');
          $item.slideUp(function() { $(this).remove() })
        }
        if (data.html) {
          let $html = $(data.html);
          const isOpened = $('#myDownloads').hasClass('cb-bottom-sheet--expanded')
          $('#myDownloads').replaceWith($html);
          app.initialize( $('#myDownloads') )
          if (isOpened) {
            $('#myDownloads').addClass('cb-bottom-sheet--expanded').removeClass('cb-bottom-sheet--collapsed');
          } else {
            $('#myDownloads').trigger('cb:open');
          }
        }
      }
    })
  } else {
    console.info("Already subscribed to DownloadChannel.");
  }
})
