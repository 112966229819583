function show(message){
  message = message || '';
  if (!message.length) return;

  let $snackTemplate = $('.cb-snackbar-template').first();
  if (!$snackTemplate) return;

  let $snack = $snackTemplate.clone();
  $snack.removeClass('cb-snackbar-template');
  $snack.addClass('mdc-snackbar mdc-snackbar--leading');
  $('body').append($snack);

  let timeout = (message.length / 15) * 1000;
  if (timeout > 10000) timeout = 10000;
  if (timeout < 4000) timeout = 4000;

  // Init new snackbar;
  window.mdcInitSnackbar();

  // CustomerEmployee tests info offset
  let $ceInfo = $('.cb-ce-test-info');
  if ($ceInfo.length) {
    let heightOffset = $ceInfo.outerHeight();
    $snack.css('bottom', '+=' + heightOffset);
  }

  $snack[0].mdcSnackbar.labelText = message;
  $snack[0].mdcSnackbar.timeoutMs = timeout;
  $snack[0].mdcSnackbar.open();
}

module.exports = { show };
