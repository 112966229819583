const form = require("../common/form");

$(document).on('page:change', function(){
  $('.cb-box-select__item-wrapper[data-url]').each(function() {
    let $block = $(this),
        url = $block.data('url');

    $.ajax({
      url: url,
      dataType: 'JSON',
      success: function (data, status) {
        // Set value
        $block.find('.cb-box-select__item-count').html(data.text);

        // Set active item label (LT)
        if ($block.find('.cb-box-select__item').hasClass('cb-box-select__item--active')) {
          form.setDeadlinOffsetLabelData($block);
        }
      },
      error: function() {
        // Maybe later
      },
      complete: function() {
        let $progress = $block.find('.cb-box-select__item-progressbar .mdc-linear-progress');
        if ($progress.length && $progress[0].mdcLinearProgress) {
          $progress[0].mdcLinearProgress.foundation.close();
        }
      }
    });
  });
})
