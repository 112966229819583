$(document).on('click', '.view-switch a, .view-switch button', function(e) {
  let $active = $(this),
      $viewSwitch = $active.closest('.view-switch'),
      indicator;

  $viewSwitch.find('.active').removeClass('active');
  $active.addClass('active');

  if ($viewSwitch[0].indicator) {
    let offset = this.offsetLeft;
    $viewSwitch[0].indicator.addClass('active');
    $viewSwitch[0].indicator.css({'transform' : `translateX(${offset}px)`});
  }
});
