const snackbar = require("./snackbar");

function submit($form) {
  if ($form.length) {
    if ($form.data('remote')) {
      Rails.fire($form[0], 'submit');
    } else {
      $form.submit();
    }
  }
}

// Modal footer save button
$(document).on('click', '.modal-footer #cbModalSave', function() {
  var $saveBtn = $(this),
      $contentBody = $saveBtn.closest('.modal-content').find('.modal-body'),
      $contentFooter = $saveBtn.closest('.modal-content').find('.modal-footer'),
      $form = $contentBody.find('form:not(.disabled)');

  $contentBody.find('textarea.ckeditor').each(function () {
     var $textarea = $(this),
         instance = CKEDITOR.instances[$textarea.attr('id')];

     if (instance && $textarea) $textarea.val(instance.getData());
  });

  submit($form);
  remoteSaveDisableWithText($form, $saveBtn, $contentFooter);
});

$(document).on('click', '#customerChartContainer .tree-branch .tree-branch__toggle', function(e) {
  e.stopPropagation();
  var $this = $(this);
      isOpen = $this.hasClass('open');

  $this.toggleClass('open');
  $this.closest('li').children('ul').slideToggle(!isOpen);
  if (isOpen) { $this.html('add'); } else { $this.html('remove'); }
});

$(document).on('click', '.cb-form-info.cb-form-info--close .cb-form-info__close', function() {
  var $infoBlock = $(this).closest('.cb-form-info');
  $infoBlock.remove();
});

$(document).on('change', '.cb-selectable-input__checkbox', function() {
  var checked = $(this).prop('checked'),
      $inputScope = $($(this).data('target')),
      inputState = !checked;

  $inputScope.each(function() {
    if (this.mdcFormField) {
      // Checkboxes and Radio buttons
      if (this.mdcFormField.input.setDisabled) {
        this.mdcFormField.input.setDisabled(inputState);
      } else {
        this.mdcFormField.input.disabled = inputState;
      }
    } else if (this.mdcSwitch) {
      $(this).find('input').attr('disabled', inputState);
      this.mdcSwitch.disabled = inputState;
    } else {
      $(this).find('input').attr('disabled', inputState);
      $(this).prop('disabled', inputState);
      if (this.mdcTextField) this.mdcTextField.disabled = inputState;
    }
    // lables etc;
    this.classList.toggle('disabled', inputState);
  });
});



$(document).on('click keydown', '.cb-box-select__item-wrapper', function(e) {
  if (e.type === 'keydown') {
    if (e.key !== "Enter" && e.key !== " ") return;

    // Prevent default action for keypresses to avoid unintended behavior
    if (e.type === 'keydown') { e.preventDefault(); }
  }

  let $item = $(this).find('.cb-box-select__item'),
      $group = $item.closest('.cb-box-select'),
      class_name = 'cb-box-select__item--active',
      target = $group.attr('data-target'),
      $target = $(target),
      targetVal = $item.attr('data-value');

  // Visual
  let $prevActive = $group.find('.' + class_name);

  $prevActive.find('.cb-box-select__item-indicator').html('');
  $prevActive.removeClass(class_name);
  $item.addClass(class_name);
  $item.find('.cb-box-select__item-indicator').html('done');

  // Value
  $target.val(targetVal);

  // Show all button
  let params = JSON.stringify({ offset: targetVal });
  $group.closest('form').find('.cb-box-select-show-all').attr('data-params', params);

  // Curent selected item label;
  setDeadlinOffsetLabelData($item);
});

function setDeadlinOffsetLabelData($activeItem) {
  let $label = $('.deadline-offset-label');
  if (!$label) return;

  let vHash = {
    '__period': '__item-title',
    '__date': '__item-subtitle',
    '__count': '__item-count'
  }

  for (var key in vHash){
    $label.find('[class$="'+ key +'"]').text($activeItem.find('[class$="'+ vHash[key] +'"]').text());
  }
}


function remoteSaveDisableWithText($form, $saveBtn, $btnWrapper){
  if ($form.data('remote')){
    $saveBtn = $saveBtn || $form.find('button[type="submit"]');
    var $btnlabel = $saveBtn.find('.mdc-button__label'),
        disabledLabel = $saveBtn.data('disable-with');

    $saveBtn.attr('data-label', $btnlabel.text());
    $saveBtn.prop('disabled', true);

    if ($btnWrapper) $btnWrapper.find('button').prop('disabled', true);
    if (disabledLabel) $btnlabel.text(disabledLabel);
  }
}

function remoteSaveDisableWithRevert($saveBtn) {
  var btnLabel = $saveBtn.attr('data-label') || I18n.t('actions.save.title');
  $saveBtn.find('.mdc-button__label').text(btnLabel);
  $saveBtn.prop('disabled', false)
}

module.exports = { submit, remoteSaveDisableWithText, remoteSaveDisableWithRevert, setDeadlinOffsetLabelData };
