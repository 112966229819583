$(document).on('change', '#customer_instruction_form_type', function() {
  let value = $(this).val();
  $('form#new_customer_instruction fieldset').prop('disabled', true).hide();
  $('#fieldset_for_'+value).prop('disabled', false).show();
})

$(document).on('change', '#customer_instruction_content_type', function() {
  let value = $(this).val(),
      asDescription = value == 'description';
  if (asDescription) {
    toggleInstructionInput('description', true)
    toggleInstructionInput('file', false)
  } else {
    toggleInstructionInput('description', false)
    toggleInstructionInput('file', true)
  }
  $('.customer_instruction_file_download').toggle(!asDescription);
})

$(document).on('change', '#customer_instruction_instruction_id', function() {
  var id = $(this).val();

  if(id){
    $.ajax({
      url: '/instructions/'+id+'.json',
      success:function (data, status) {
        $('#customer_instruction_instruction_id').val(id)
        $('#customer_instruction_type').val(data.type).change();
        $('#customer_instruction_name').val(data.name).change();
        $('#customer_instruction_number').val(data.number).change();
        $('#customer_instruction_content_type').val(data.content_type).change();

        // Hide file input When using any template
        toggleInstructionInput('file', false);

        if (data.content_type == 'file' && data.file_file_name) {
          $('.customer_instruction_content_type').hide();
          toggleInstructionInput('description', false)
        } else {
          $('.customer_instruction_content_type').show();
          toggleInstructionInput('description', true)
          CKEDITOR.instances['instruction_description'].setData(data.description);
        }
      }
    });
  } else {
    toggleInstructionInput('file', true);
    $('.customer_instruction_content_type').show();
  }
});

$(document).on('keyup', '.instruction-search', function() {
  var value = this.value.toLowerCase().trim();
  $(this).closest('.instruction-search-container').find('.instruction-search-item').show().filter(function () {
    return $(this).text().toLowerCase().trim().indexOf(value) == -1;
  }).hide();
})

$(document).on('change', '.instruction-search-container input[type="checkbox"]', function() {
  var $container = $(this).closest('.instruction-search-container');
  var checkedItemsCount = $container.find('input[type="checkbox"]:checked').not('.cb-check-all').length;
  var $counter = $container.find('.selected-instructions');
  $counter.find('.counter').html(I18n.t('selected', { count: checkedItemsCount }));
})

function toggleInstructionInput(field, toggle, prefix) {
  if (prefix == undefined) { prefix = 'customer_instruction' }
  $('#'+prefix+'_'+field).prop('disabled', !toggle);
  $('.'+prefix+'_'+field).toggle(toggle);
}
