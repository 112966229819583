const camalcase = (str) => {
  return str.toLowerCase().replace(/-([a-zA-Z0-9])/g, (m, chr) => chr.toUpperCase());
}

const dasherize = (str) => {
  return str.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase());
}

var dashedToCamelCase = function (dashed) {
  var i;
  var camel = '';
  var nextCap = false;
  for (i = 0; i < dashed.length; i++) {
    if (dashed[i] !== '-') {
      camel += nextCap ? dashed[i].toUpperCase() : dashed[i];
      nextCap = false;
    } else {
      nextCap = true;
    }
  }
  return camel;
};

const extractProps = (element) => {
  const attributes = [...element.attributes];
  const entries = attributes.map((value) => {
    return [camalcase(value.name), value.value];
  });

  return Object.fromEntries(entries);
}

const emitEvent = (type, detail = {}, elem = document) => {
  if (!type) return;

  const options = {
    bubbles: true,
    cancelable: false,
    composed: true,
    detail: detail,
  }

  const event = new CustomEvent(type, options);
  return elem.dispatchEvent(event);
}

// Utility Functions
const requestData = (target, params, successCallback, additionalCallback) => {
  const url = new URL(target, window.location.origin);

  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, value);
    } else {
      url.searchParams.append(key, '');
    }
  })

  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text() || response.json();
    })
    .then(data => {
      successCallback(data);
      if (additionalCallback) {
        additionalCallback();
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}

const requireAll = (r) => { return r.keys(); }

export {
  extractProps,
  emitEvent,
  requestData,
  camalcase,
  dasherize,
  requireAll
};
