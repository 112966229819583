/*
  Shepherd
*/

import Shepherd from 'shepherd.js';



function initUserTour() {
  [].map.call(document.querySelectorAll('.tour[data-tour]'), function(el) {
    let userTour = tourFor(el);
    if (!userTour) return;

    el.userTour = userTour;
    if (el.userTour.auto_start) el.userTour.start();

    el.addEventListener('click', function(){
      this.userTour.start();
    });
  });
}

function tourFor(element) {
  let $el = $(element),
      data = $el.attr('data-tour');

  if (!data) return;

  data = JSON.parse(data);
  if (!Object.keys(data).length) return;

  // Create empty tour
  const tour = shepherdTour(data.tour);

  // Add steps
  let steps = data.steps,
      tourSteps = [];

  if (!steps.length) return;

  for (const idx in steps) {
    let step = steps[idx];
    if (!step.text) continue;

    // Attach to element
    if (step.attachTo) {
      if (typeof step.attachTo !== "object"){
        step.attachTo = { element: step.attachTo };
      }
      if (!step.attachTo.on) step.attachTo.on = 'auto-start';
    }

    // Navigation buttons
    step.buttons = [];
    if (idx != 0) {
      step.buttons.push({
        text: I18n.t('tour.step.prev'),
        action: tour.back
      })
    }
    let is_last_step = steps.length - 1 == idx;
    step.buttons.push({
      text: (is_last_step ? I18n.t('tour.step.done') : I18n.t('tour.step.next')),
      action: tour.next
    });

    // Advance on clincking overlay
    // step.advanceOn = {
    //   selector: 'svg.shepherd-modal-overlay-container path',
    //   event: 'click'
    // }

    // When (callbacks)
    step.when = {};
    if (step.when_options) {
      for (const step_action in step.when_options) {
        let stepFunction = eval(step.when_options[step_action].name),
            stepFunctionData = step.when_options[step_action].data;

        step.when[step_action] = function() {
          if (typeof stepFunction === 'function') stepFunction(stepFunctionData);
        }
      }
    }

    // Before show (when completed function)
    if (step.beforeShow) {
      step.beforeShowPromise = function() {
        let stepFunction = eval(step.beforeShow.name),
            stepFunctionData = step.beforeShow.data;

        return new Promise(function(resolve) {
          if (typeof stepFunction === 'function') stepFunction(stepFunctionData, resolve);
        });
      }
    }

    // Push step to tour step collection
    tourSteps.push(step);
  }
  tour.addSteps(tourSteps);

  tour.on('complete', function(){
    revertOnTourEnding(event);
  });

  tour.cancel = function() {
    const defaultCalcel = function(thisTour) {
      if (thisTour.options.confirmCancel) {
        const cancelMsg = thisTour.options.confirmCancelMessage || I18n.t('tour.cancel.message');
        const stopTour = window.confirm(cancelMsg);
        if (stopTour) thisTour._done('cancel');

      } else {
        thisTour._done('cancel');
      }
    };

    const currentStep = this.getCurrentStep(),
          tourStartButton = this.getById('tourStartButton');

    // Revert all in steps (drawers, modals, etc.)
    revertOnTourEnding();

    if (tourStartButton && currentStep.id != 'tourStartButton') {
      this.show('tourStartButton', true);
    } else {
      defaultCalcel(this);
    }
  }

  return tour;
}


// Step functions (when)
function toggleDrawerStep(data, callback){
  let drawer = document.querySelector('aside.cb-drawer')
  if (drawer && drawer.mdcDrawer && drawer.mdcDrawer.open != data.open) {
    let mdcEventName = data.open ? 'MDCDrawer:opened' : 'MDCDrawer:closed';
    drawer.addEventListener(mdcEventName, (event) => {
      if (typeof callback === 'function') callback();
    });
    drawer.mdcDrawer.open = data.open;
  } else {
    if (typeof callback === 'function') callback();
  }
}

function findTawkToBubble(data, callback) {
  var tawkToBubbleIframe = $('body > div[id] iframe').first();
  if (tawkToBubbleIframe.length) {
    // Reset all style
    tawkToBubbleIframe.attr('style', '')

    var bubbleParent = tawkToBubbleIframe.closest('div');
    bubbleParent.attr('id', 'tawkToBubbleWrapper');
  }

  if (typeof callback === 'function') callback();
}

// Revert all events on last step
function revertOnTourEnding(data, callback) {
  toggleDrawerStep({ open: false });
}

function shepherdTour(tour_data) {
  const tour = new Shepherd.Tour({
    tourName: tour_data.key,
    defaultStepOptions: {
      scrollTo: true,
      canClickTarget: false,
      cancelIcon: {
        enabled: true,
        label: I18n.t('actions.close')
      }
    },
    useModalOverlay: true
  });

  tour.auto_start = tour_data.auto_start;
  return tour;
}

$(document).on("page:change", function() {
  initUserTour();
});
