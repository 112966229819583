// Add variable key at the end of input (text)
$(document).on('click', 'form .template-variables .template-variables__key', function() {
  let text = $(this).text(),
      $fieldset = $(this).closest('fieldset'),
      $input = $fieldset.find('[id^="notification_template"][id$="_description"]'),
      currentVal = $input.val(),
      newVal;

  if (currentVal.length) {
    // Add space if not ending with space
    if (!currentVal.endsWith(' ')) text = ' ' + text;
    newVal = currentVal + text;
  } else {
    newVal = text;
  };

  $input.val(newVal);

  // Toggle mdcTextField label
  let field = $input.closest('.mdc-text-field')[0];
  if (field && field.mdcTextField) field.mdcTextField.blur();

  templatePreview($fieldset, newVal);
});

function templatePreview($fieldset, description) {
  let url = $('.edit_notification_template').attr('action'),
      contentBlockSelector = '.template-preview .template-preview__msg',
      $contentBlock;

  // Set message preview target block
  if ($fieldset) {
    $contentBlock = $fieldset.find(contentBlockSelector);
  } else {
    $contentBlock = $(contentBlockSelector);
  }

  description = description || $('#notification_template_description').val();
  loadTemplatePreview(url, description, $contentBlock);
}

function loadTemplatePreview(url, description, $block) {
  let locale = $block.attr('data-locale'),
      txt = description,
      params = { user_id: currentUser, example: true };

  if (locale) {
    params.description = description;
    params.locale = locale;
  }

  // Load data for preview
  $.ajax({
    url: url + '.json',
    data: params,
    success:function (data, status) {
      if (locale) { txt = data.description }

      description = templatePreviewContent(txt, data.values);

      // Replace preview block witj new html
      $block.html(description);

      $block.find('[data-toggle="tooltip"]').tooltip();
    }
  });
}

function templatePreviewContent(description, values) {
  if (!description) return '<div class="center">...</div>';

  let span = $('<span>');

  // Escape all html, so that input value html tags won't be rendered in preview
  let escaped_desc = $('<span>').text(description).html();

  // Replace all variables with preview data
  for (const key in values) {
    k = `%{${key}}`;
    v = span.clone().text(values[key]).attr('data-key', k);
    escaped_desc = escaped_desc.replaceAll(k, v[0].outerHTML);
  }

  return escaped_desc;
}


// Init all previews (translations) when opening form
$(document).on("page:change", function() {
  // #notification_template_description for all locales
  let $inputs = $('[id^="notification_template"][id$="_description"]');

  if ($inputs.length) {
    $inputs.each(function(idx, item){
      let $fieldset = $(item).closest('fieldset'),
          description = $(item).val();

      templatePreview($fieldset, description);
    })
  }

  // Show fields preview rendering - replace content
  if ($('#templatePreviewContent')) {
    let url = window.location.pathname;
    $('#templatePreviewContent').find('.template-preview__msg').each(function(idx, item){
      let $block = $(item),
          description = $block.text();

      loadTemplatePreview(url, description, $block);
    });
  }
});

$(document).on('keyup', '[id^="notification_template"][id$="_description"]', keyupDelay(function(){
  let $fieldset = $(this).closest('fieldset'),
      description = $(this).val();

  templatePreview($fieldset, description);
}, 400));

function keyupDelay(callback, ms) {
  var timer;
  return function() {
    var context = this, args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}
