$(document).on('change', '#methodology_content_type', function() {
  let value = $(this).val();

  if (value == 'content') {
    toggleContent('content', true);
    toggleContent('file', false);
  } else {
    toggleContent('content', false);
    toggleContent('file', true);
  }
})

function toggleContent(field, toggle) {
  $('#methodology_'+ field).prop('disabled', !toggle);
  $('.methodology_'+ field).toggle(toggle);
}
