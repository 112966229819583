$(document).on('change', '#assignment_assignment_template_id', function () {
  let id = $(this).val();

  if (id) {
    $.ajax({
      url: '/assignment_templates/' + id + '.json',
      success: function (data, status) {
        $('#assignment_subject').val(data.subject).trigger('change');
        $('#assignment_location').val(data.location).trigger('change');
        CKEDITOR.instances['assignment_template_description'].setData(data.description);
      }
    });
  } else {
    CKEDITOR.instances['assignment_template_description'].setData();
  }
});

$(document).on('change', '.assignments-user-select', function () {
  let id = $(this).val(),
    parent = $(this).closest('.select, .mdc-layout-grid__inner').parent(),
    person_name_field = parent.find('[name$="[name]"]'),
    person_suname_field = parent.find('[name$="[surname]"]'),
    person_occupation_field = parent.find('[name$="[occupation]"]')

  $.ajax({
    url: '/users/' + id,
    dataType: 'JSON',
    success: function (data) {
      person_name_field.val(data['name']).change();
      person_suname_field.val(data['surname']).change();
      person_occupation_field.val(data['occupation_name']).change();
    }
  })
});

$(document).on("click", "#assignment_template-params-wrapper .template-param-button", function () {
  var $item = $(this),
    key = $item.data('key'),
    editor = CKEDITOR.instances['assignment_template_description'];

  if (key) {
    var html = '<span>%{' + key + '}</span>';
    var newElement = CKEDITOR.dom.element.createFromHtml(html, editor.document);
    editor.insertElement(newElement);
  }
})

$(document).on('change', '#assignment_template_assignable_type', function () {
  let class_name = $(this).val();

  if (class_name) {
    $.get({
      url: '/assignment_templates/load_template_params.js',
      data: { class_name: class_name }
    });
  } else {
    $('#assignment_template-params-wrapper').empty();
  }
});

$(document).on('change', '#assignment_content_type', function () {
  let value = $(this).val(),
    asDescription = value == 'description';
  if (asDescription) {
    toggleAssignmentInput('description', true)
    toggleAssignmentInput('is_esigned', true)
    toggleAssignmentInput('file', false)
    toggleAssignmentInput('is_watermarked', false)
    $('#assignment-ckeditor-wrapper').show();
  } else {
    toggleAssignmentInput('description', false)
    toggleAssignmentInput('is_esigned', false)
    toggleAssignmentInput('file', true)
    toggleAssignmentInput('is_watermarked', true)
    $('#assignment-ckeditor-wrapper').hide();
  }
})

function toggleAssignmentInput(field, toggle, prefix) {
  if (prefix == undefined) { prefix = 'assignment' }
  $('#' + prefix + '_' + field).prop('disabled', !toggle);
  $('.' + prefix + '_' + field).toggle(toggle);
}
